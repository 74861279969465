import { client } from "./client";

export const addressService = {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  getAddresses(params: any): any {
    return client.get("/shop/addresses", { params }).then((res) => res.data);
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  createAddress(params: any): any {
    return client.post("/shop/addresses", params).then((res) => res.data);
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  updateAddress(id: number, params: any): any {
    return client.put(`/shop/addresses/${id}`, params).then((res) => res.data);
  },
};
